import { IRole } from "./role";
import { ITag } from "./tag";

export enum UserRegisterBy {
  internal = "internal",
  social = "social",
}

export enum SocialLoginProvider {
  facebook = "facebook",
  google = "google",
}

export interface IOAuthData {
  name: string;
  socialLoginId: string;
  email: string;
}

export enum UserAuthority {
  user = "user",
  internal = "internal",
}

export enum UserTitleType {
  doctor = "doctor",
}
export enum PermissionsEnum {
  CAN_VIEW_STATS = "CAN_VIEW_STATS",
  CAN_MANAGE_USERS = "CAN_MANAGE_USERS",
  CAN_MANAGE_TAGS = "CAN_MANAGE_TAGS",
  CAN_MANAGE_MEDICAL_QUESTIONS = "CAN_MANAGE_MEDICAL_QUESTIONS",
  CAN_MANAGE_PACKAGES = "CAN_MANAGE_PACKAGES",
  CAN_MANAGE_CONSULTING = "CAN_MANAGE_CONSULTING",
  CAN_MANAGE_HOME_VIDEO_CATEGORIES = "CAN_MANAGE_HOME_VIDEO_CATEGORIES",
  CAN_MANAGE_HOME_BANNERS = "CAN_MANAGE_HOME_BANNERS",
  CAN_MANAGE_ARTICLES = "CAN_MANAGE_ARTICLES",
  CAN_MANAGE_COMPLAINTS = "CAN_MANAGE_COMPLAINTS",
  CAN_MANAGE_NOTIFICATIONS = "CAN_MANAGE_NOTIFICATIONS",
  CAN_MANAGE_SETTINGS = "CAN_MANAGE_SETTINGS",
  CAN_MANAGE_ROLES = "CAN_MANAGE_ROLES",
}

export interface IUser {
  _id: string;

  name: string;

  phone: string;

  email: string;

  dateOfBirth?: Date;

  height?: number;

  weight?: number;

  gender?: "male" | "female";

  image?: string;

  password?: string;

  verified: boolean;

  registeredBy: UserRegisterBy;

  socialLoginId?: String;

  socialLoginProvider?: SocialLoginProvider;

  socialLoginEmail?: string;

  authority: UserAuthority;

  notificationAllowedForAllDevices?: boolean;

  role?: IRole;

  unReadNotifications: number;

  titleType?: UserTitleType | null;

  title?: string;

  biography?: string;

  titlePosition?: number;

  workingHoursStart?: number;

  workingHoursEnd?: number;

  workingTimeZone?: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt?: Date;

  lastSeenAt?: Date;

  isOnline?: boolean;

  tag?: ITag[];
}
